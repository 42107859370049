<template>
  <div class="my-2">
    <span class="back-btn tnt-main-color" @click="nav">
      <b-icon-chevron-left />
      Kembali
    </span>
  </div>
</template>

<script>
export default {
  name: "BackBtn",

  props: ["prevRoute"],

  components: {},

  data: () => ({}),

  computed: {},

  mounted() {},

  created() {},

  methods: {
    nav() {
      if (this.prevRoute != null && this.prevRoute != "") {
        this.$router.push({ name: this.prevRoute });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped>
.back-btn {
  cursor: pointer;
  margin: 10px 0;
  text-align: left !important;
}
</style>