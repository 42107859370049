<template>
  <div class="my-2 mb-3">
    <span v-for="(v, i) in arrDat" :key="'humzzz-' + i" class="mr-1">
      <template v-if="i + 1 != arrDat.length">
        <router-link
          :to="{ name: v.route }"
          class="tnt-main-color this-hover"
          style="font-weight:600"
          >{{ v.text }}</router-link
        >
        <b-icon-chevron-right
          class="active-route"
          style="height: 12px; margin-bottom: 1px"
        />
      </template>
      <template v-else>
        <span class="active-route">{{ v.text }}</span>
      </template>
    </span>
  </div>
</template>


<script>
export default {
  name: "Breadcrumb",

  props: {
    Dashboard: Boolean,
    Attendance: Boolean,
    AttendanceHistory: Boolean,
    AttendancePicture: Boolean,
    AttendanceRequestForm: Boolean,

    Timeoff: Boolean,
    TimeoffForm: Boolean,

    Reimburse: Boolean,
    ReimburseCreate: Boolean,
    ReimburseEdit: Boolean,
    ReimburseDetail: Boolean,

    CashAdvance: Boolean,
    CashAdvanceDetail: Boolean,
    CashAdvanceAdd: Boolean,

    Approval: Boolean,
    ApprovalHistory: Boolean,
    ApprovalDetail: Boolean,

    SuratTugas: Boolean,
    SuratTugasCreate: Boolean,
    SuratTugasDetail: Boolean,
    SuratTugasHistory: Boolean,

    Kasir: Boolean,
    KasirDetail: Boolean,

    FinanceCashAdvance: Boolean,

    FinanceReimburse: Boolean,
    FinanceApprovalReimburse: Boolean,

    PaymentVoucher: Boolean,
    PaymentVoucherDetail: Boolean,

    PettyCash: Boolean,
    PettyCashTopup: Boolean,
    SuratTugasManage: Boolean,

    AttendanceReport: Boolean,
    AttendanceSetting: Boolean,

    Announcement: Boolean,
    AnnouncementCreate: Boolean,
    AnnouncementEdit: Boolean,

    Category: Boolean,
    Company: Boolean,
    Division: Boolean,
    CompanyPettyCash: Boolean,
    Employee: Boolean,
    EmployeeAdd: Boolean,
    EmployeeEdit: Boolean,
    EmployeeContact: Boolean,

    CompanyBank: Boolean,

    Calendar: Boolean,

    Settings: Boolean,
    SettingProfile: Boolean,
    SettingCompany: Boolean,
    SettingBank: Boolean,
    SettingPassword: Boolean,
    SettingBank3: Boolean,
    LoginDevices: Boolean,

    ChangeLog: Boolean,

    Notification: Boolean
  },

  components: {},

  data: () => ({
    arrDat: [],
  }),

  computed: {},

  mounted() {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      if (this.Dashboard)
        this.arrDat.push({
          route: "Dashboard",
          text: "Dashboard",
        });

      if (this.Attendance)
        this.arrDat.push({
          route: "Attendance",
          text: "Kehadiran",
        });
      if (this.AttendanceHistory)
        this.arrDat.push({
          route: "AttendanceHistory",
          text: "Riwayat",
        });
      if (this.AttendancePicture)
        this.arrDat.push({
          route: "AttendancePicture",
          text: "Form",
        });
      if (this.AttendanceRequestForm)
        this.arrDat.push({
          route: "AttendanceRequestForm",
          text: "Pengajuan",
        });

      if (this.Timeoff)
        this.arrDat.push({
          route: "Timeoff",
          text: "Cuti",
        });

      if (this.TimeoffForm)
        this.arrDat.push({
          route: "TimeoffForm",
          text: "Pengajuan Cuti",
        });

      if (this.Reimburse)
        this.arrDat.push({
          route: "Reimburse",
          text: "Reimburse",
        });
      if (this.ReimburseCreate)
        this.arrDat.push({
          route: "Reimburse",
          text: "Buat",
        });
      if (this.ReimburseEdit)
        this.arrDat.push({
          route: "Reimburse",
          text: "Edit Reimburse",
        });
      if (this.ReimburseDetail)
        this.arrDat.push({
          route: "ReimburseDetail",
          text: "Detail",
        });

      if (this.CashAdvance)
        this.arrDat.push({
          route: "CashAdvance",
          text: "Cash Advance",
        });
      if (this.CashAdvanceDetail)
        this.arrDat.push({
          route: "CashAdvanceDetail",
          text: "Detail",
        });
      if (this.CashAdvanceAdd)
        this.arrDat.push({
          route: "CashAdvanceAdd",
          text: "Buat Cash Advance",
        });

      if (this.Approval)
        this.arrDat.push({
          route: "Approval",
          text: "Approval",
        });

      if (this.ApprovalHistory)
        this.arrDat.push({
          route: "ApprovalHistory",
          text: "Riwayat",
        });

      if (this.ApprovalDetail)
        this.arrDat.push({
          route: "ApprovalDetail",
          text: "Detail",
        });

      if (this.SuratTugas)
        this.arrDat.push({
          route: "SuratTugas",
          text: "Surat Tugas",
        });
      if (this.SuratTugasCreate)
        this.arrDat.push({
          route: "SuratTugasCreate",
          text: "Buat",
        });
      if (this.SuratTugasDetail)
        this.arrDat.push({
          route: "SuratTugasDetail",
          text: "Detail",
        });
      if (this.SuratTugasHistory)
        this.arrDat.push({
          route: "SuratTugasHistory",
          text: "Riwayat",
        });

      if (this.Kasir)
        this.arrDat.push({
          route: "Kasir",
          text: "Kasir Petty Cash",
        });

      if (this.KasirDetail)
        this.arrDat.push({
          route: "KasirApproval",
          text: "Detail",
        });

      if (this.FinanceCashAdvance)
        this.arrDat.push({
          route: "FinanceCashAdvance",
          text: "Finance Cash Advance",
        });

      if (this.FinanceReimburse)
        this.arrDat.push({
          route: "FinanceReimburse",
          text: "Finance Reimburse",
        });

      if (this.FinanceApprovalReimburse)
        this.arrDat.push({
          route: "FinanceApprovalReimburse",
          text: "Detail",
        });

      if (this.PaymentVoucher)
        this.arrDat.push({
          route: "PaymentVoucher",
          text: "Payment Voucher",
        });
      if (this.PaymentVoucherDetail)
        this.arrDat.push({
          route: "PaymentVoucherDetail",
          text: "Detail",
        });

      if (this.PettyCash)
        this.arrDat.push({
          route: "PettyCash",
          text: "Top Up Petty Cash",
        });
      if (this.PettyCashTopup)
        this.arrDat.push({
          route: "PettyCashTopup",
          text: "Pengajuan",
        });

      if (this.SuratTugasManage)
        this.arrDat.push({
          route: "SuratTugasManage",
          text: "Laporan Surat Tugas",
        });

      if (this.AttendanceReport)
        this.arrDat.push({
          route: "AttendanceReport",
          text: "Laporan Kehadiran",
        });

      if (this.AttendanceSetting)
        this.arrDat.push({
          route: "AttendanceSetting",
          text: "Setting Jadwal Kehadiran",
        });

      if (this.Announcement)
        this.arrDat.push({
          route: "Announcement",
          text: "Pengumuman",
        });
      if (this.AnnouncementCreate)
        this.arrDat.push({
          route: "AnnouncementCreate",
          text: "Buat Pengumuman",
        });
      if (this.AnnouncementEdit)
        this.arrDat.push({
          route: "AnnouncementEdit",
          text: "Edit Pengumuman",
        });

      if (this.Category)
        this.arrDat.push({
          route: "InformationCategory",
          text: "Kategori",
        });
      if (this.Company)
        this.arrDat.push({
          route: "InformationCompany",
          text: "Perusahaan",
        });
      if (this.Division)
        this.arrDat.push({
          route: "InformationDivision",
          text: "Divisi",
        });
      if (this.EmployeeContact)
        this.arrDat.push({
          route: "ContactNumber",
          text: "Kontak Karyawan",
        });
      if (this.Calendar)
        this.arrDat.push({
          route: "Calendar",
          text: "Kalender",
        });

      if (this.CompanyPettyCash)
        this.arrDat.push({
          route: "CompanyPettyCash",
          text: "Pengaturan Petty Cash",
        });
      if (this.Employee)
        this.arrDat.push({
          route: "Employee",
          text: "Karyawan",
        });
      if (this.EmployeeAdd)
        this.arrDat.push({
          route: "EmployeeAdd",
          text: "Registrasi Karyawan",
        });
      if (this.EmployeeEdit)
        this.arrDat.push({
          route: "EmployeeEdit",
          text: "Ubah Karyawan",
        });

      if (this.CompanyBank)
        this.arrDat.push({
          route: "CompanyBank",
          text: "Bank Perusahaan",
        });

      if (this.Settings)
        this.arrDat.push({
          route: "Settings",
          text: "Akun",
        });

      if (this.SettingProfile)
        this.arrDat.push({
          route: "SettingProfile",
          text: "Profil Pribadi",
        });
      if (this.SettingCompany)
        this.arrDat.push({
          route: "SettingCompany",
          text: "Profil Perusahaan",
        });
      if (this.SettingBank)
        this.arrDat.push({
          route: "SettingBank",
          text: "Rekening Bank Saya",
        });
      if (this.SettingPassword)
        this.arrDat.push({
          route: "SettingPassword",
          text: "Ganti Password",
        });
      if (this.SettingBank3)
        this.arrDat.push({
          route: "SettingBank3",
          text: "Rekening Bank Pihak Ke-3",
        });
      if (this.LoginDevices)
        this.arrDat.push({
          route: "LoginDevices",
          text: "Perangkat Aktif",
        });
      if (this.ChangeLog)
        this.arrDat.push({
          route: "ChangeLog",
          text: "Change Log",
        });
      if (this.Notification)
        this.arrDat.push({
          route: "notifikasi",
          text: "Notifikasi",
        });
    },
  },
};
</script>

<style scoped>
.active-route {
  color: #808080;
  font-weight: 600;
}

.this-hover:hover {
  text-decoration: none;
  color: #006784;
}
</style>