<template>
  <!-- Sidebar -->
  <!--
    Sidebar Mini Mode - Display Helper classes

    Adding 'smini-hide' class to an element will make it invisible (opacity: 0) when the sidebar is in mini mode
    Adding 'smini-show' class to an element will make it visible (opacity: 1) when the sidebar is in mini mode
    If you would like to disable the transition animation, make sure to also add the 'no-transition' class to your element

    Adding 'smini-hidden' to an element will hide it when the sidebar is in mini mode
    Adding 'smini-visible' to an element will show it (display: inline-block) only when the sidebar is in mini mode
    Adding 'smini-visible-block' to an element will show it (display: block) only when the sidebar is in mini mode
    -->
  <nav id="sidebar" aria-label="Main Navigation" :style="getBgColor()">
    <slot>
      <!-- Side Header -->
      <div class="content-header">
        <!-- Logo -->
        <router-link to="/" class="font-w600 text-dual">
          <img src="../../assets/icons/logo-sidebar-dark.svg" />
        </router-link>
        <!-- END Logo -->

        <!-- Extra -->
        <div>
          <!-- Close Sidebar, Visible only on mobile screens -->
          <base-layout-modifier
            size="sm"
            variant="dual"
            action="sidebarClose"
            class="d-lg-none ml-1"
          >
            <i class="fa fa-fw fa-times"></i>
          </base-layout-modifier>
          <!-- END Close Sidebar -->
        </div>
        <!-- END Extra -->
      </div>
      <!-- END Side Header -->

      <!-- Sidebar Scrolling -->
      <simplebar class="js-sidebar-scroll" :style="getBgColor()">
        <!-- Profile -->
        <div class="d-flex p-2 mb-2 box-aa">
          <div class="mr-2">
            <b-avatar badge-variant="primary" :src="usrPhoto" size="3rem" />
          </div>
          <div class="font-common">
            <div class="font-weight-bold tnt-main-color">
              <span
                class="cursor-pointer"
                @click="$router.push({ name: 'Settings' })"
                >{{ userInfo.em_fname + " " + userInfo.em_lname }}</span
              >
            </div>
            <div>
              {{ userInfo.em_company_name }}
            </div>
            <div>
              {{
                userInfo.em_division_name + " - " + userInfo.em_position_name
              }}
            </div>
          </div>
        </div>

        <!-- Update btn -->
        <div class="d-flex justify-content-center">
          <UpdateAppBtn
            alwaysShow="false"
            btnText="Update Aplikasi"
            class="w-100 mx-4"
          />
        </div>

        <!-- All Menu -->
        <div class="content-side pt-2 box-aa">
          <base-navigation :nodes="navigation"></base-navigation>
        </div>

        <!-- Changelog -->
        <div class="text-right mt-2 mr-3 mb-3 text-dark">
          <span style="cursor: pointer" @click="toChangelog"> v{{ ver }} </span>
        </div>
      </simplebar>
      <!-- END Sidebar Scrolling -->
    </slot>
  </nav>
  <!-- END Sidebar -->
</template>

<script>
// SimpleBar, for more info and examples you can check out https://github.com/Grsmto/simplebar/tree/master/packages/simplebar-vue
import simplebar from "simplebar-vue";
import menuList from "@/data/sidebar-menu";
import UpdateAppBtn from "@/components/UpdateAppBtn";

export default {
  name: "BaseSidebar",
  props: {
    classes: String,
  },

  components: {
    simplebar,
    UpdateAppBtn,
  },

  data() {
    return {
      navigation: menuList.main,
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      ver: process.env.VUE_APP_VERSION,
      usrPhoto: null,
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$axios
        .post("/v1/user/init", {}, this.gl_req_header())
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data.em_photo !== null) {
              this.usrPhoto = res.data.data.em_photo.url_thumb;
            }
          }
        })
        .catch((e) => {
          this.notifToast("danger", e);
        });
    },

    toChangelog() {
      this.$router.push({ name: "ChangeLog" });
    },

    getBgColor() {
      let v;
      if (this.currentStage === "development") {
        v = "background: #" + this.developmentColor;
      } else if (this.currentStage === "staging") {
        v = "background: #" + this.stagingColor;
      } else {
        v = "background: #" + this.productionColor;
      }

      return v;
    },
  },
};
</script>

<style scoped>
.btn-update {
  width: 215px;
  height: 46px;
  background: #5db025;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  display: none;
  border: none;
  outline: none;
}

.font-common {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.js-sidebar-scroll {
}

.box-aa {
  background-color: white;
  /* padding: 12px 0px; */
  border-radius: 5px;
  box-shadow: 1px 1px 2px #e3e3e3;
}
</style>
